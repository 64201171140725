import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#101e5a',
        secondary: '#cbc6c3',
        info: '#5d80b6',
        accent: '#f7f8f9',
        anchor: '#101e5a',
        warning: '#ffab40',
      },
    },
  },
})
