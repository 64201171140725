import Vue from 'vue'
import './plugins/base'
import vuetify from './plugins/vuetify'
import vueObserveVisibility from './plugins/vue-observe-visibility'
import '@fontsource/roboto/100.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'
import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  data: {
    anchor: '',
  },
  vuetify,
  vueObserveVisibility,
  render: h => h(App),
}).$mount('#app')
